import React, { useState } from "react";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
} from "reactstrap";

import { Link } from "react-router-dom";

const AppNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Navbar color="dark" dark expand="md">
      <NavbarBrand
        style={{ color: "yellow", fontFamily: "CustomFont-Normal" }}
        tag={Link}
        to="/"
      >
        Yellow Corp
      </NavbarBrand>
      <NavbarToggler
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <Collapse isOpen={isOpen} navbar>
        <Nav style={{ width: "100%" }} navbar>
          <NavItem>
            {/* Useing Link from react-router-dom to allow build to include in routes */}
            <Link className="nav-link" to="/items">
              Inventory
            </Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/manifests">
              Manifests
            </Link>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default AppNavbar;

import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Container, Table } from "reactstrap";
import AppNavbar from "./AppNavbar";
import { Link } from "react-router-dom";

const ItemList = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    fetch("https://jcotton.co.uk:8080/ybm/api/inventory/all")
      .then((response) => response.json())
      .then((data) => {
        setItems(data);
        setLoading(false);
      });
  }, []);

  const remove = async (id) => {
    await fetch(`https://jcotton.co.uk:8080/ybm/api/inventory/delete/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(() => {
      let updatedItems = [...items].filter((i) => i.id !== id);
      setItems(updatedItems);
    });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  const itemList = items.map((item) => {
    return (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>{item.sku}</td>
        <td style={{ whiteSpace: "nowrap" }}>{item.name}</td>
        <td>
          {item.quantity}
        </td>
        <td>
          <ButtonGroup>
            <Button
              size="sm"
              color="primary"
              tag={Link}
              to={"/#/items/" + item.id}
            >
              Edit
            </Button>
            <Button size="sm" color="danger" onClick={() => remove(item.id)}>
              Delete
            </Button>
          </ButtonGroup>
        </td>
      </tr>
    );
  });

  return (
    <div>
      <AppNavbar />
      <Container fluid>
        <div className="float-end">
          <Button color="success" tag={Link} to="/groups/new">
            Add Item
          </Button>
        </div>
        <h3>Inventory</h3>
        <Table className="mt-4">
          <thead>
            <tr>
              <th width="20%">ID</th>
              <th width="20%">SKU</th>
              <th>Name</th>
              <th width="20%">Quantity</th>
              <th width="10%">Actions</th>
            </tr>
          </thead>
          <tbody>{itemList}</tbody>
        </Table>
      </Container>
    </div>
  );
};

export default ItemList;
